<script>
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_TEACHER_GROUPS, UPDATE_TEACHER} from "@/core/services/store/teacher/teacher.module";

export default {
  name: "Modal-Detail-And-Edit-Teacher",
  data() {
    return {
      query: {
        name: null,
        phone: null,
        email: null,
        dateOfBirth: null,
        gender: null,
        center: null,
        groupOfLecturers: null,
        trainingPassTime: null,
        status: false,
        account_type_id: 7,
        center_ids: [null]
      },
      loading: false,
      isEdit: false
    }
  },
  props:{
    lecturer:{
      type:Object,
      default() {
        return null
      }
    },
    listCenter:{
      type: Array,
      default(){
        return []
      }
    },
    listTeacherGroup:{
      type: Array,
      default(){
        return []
      }
    }
  },
  mounted() {

  },
  methods:{
   async setLecturerData(){
     console.log(this.lecturer)
     this.getDetailLecturer()
   },
    getDetailLecturer(){
      this.query = {
        name: this.lecturer.name, // Giảng viên Cambridge
        phone: this.lecturer.phone, // 0832432342
        email: this.lecturer.email, // giangviencam@gmail.com
        dateOfBirth: this.lecturer.birthday || this.lecturer.profile.birthday, // null
        gender: this.lecturer.gender || this.lecturer.profile.gender, // null
        center: this.lecturer?.profile?.center ? this.lecturer?.profile?.center?.id : null,
        groupOfLecturers: this.lecturer.teacher_groups.length ? this.lecturer.teacher_groups[0].id : null ,
        trainingPassTime: this.lecturer.teacher_infor ? this.lecturer.teacher_infor.training_pass_time : null,
        status: !!this.lecturer.status,
        account_type_id: this.lecturer?.profile?.account_type?.id,
        center_ids: [this.lecturer?.profile?.center ? this.lecturer?.profile?.center?.id : null]
      };
    },
    getCenters(){
     return  this.$store.dispatch(GET_CENTERS,{limit : 10000, status : 1}).then((res) => {
        return this.listCenter = res.data.data;
      }).finally(()=>{
        return null
     });
    },
    getTeacherGroups(){
      return this.$store.dispatch(GET_TEACHER_GROUPS).then((data)=>{
        return this.listTeacherGroup = data.data
      }).finally(()=>{
        return null
      })
    },
    closeModal(){
      this.$bvModal.hide('modal-detail-teacher')
    },
    cancelUpdate(){
      this.getDetailLecturer()
      this.isEdit = false;
    },
    updateLecturer(){
     this.loading = true
     const params = {
       name: this.query.name,
       phone: this.query.phone,
       email: this.query.email,
       status: this.query.status,
       account_type_id: 7,
       birthday: this.query.dateOfBirth,
       gender: this.query.gender,
       trung_tam_id: this.query.center,
       teacher_group: this.query.groupOfLecturers,
       training_pass_time: this.query.trainingPassTime,
       center_ids: [this.query.center]
     }
      this.$store.dispatch(UPDATE_TEACHER, {
        id: this.lecturer.id,
        params : params
      }).then((res) => {
        if (!res.error) {
            this.$bvToast.toast(res.message, {
              title: 'Sửa giảng viên',
              variant: 'success',
              solid: true
            });
          this.isEdit = false;
          this.$emit('reloadListLecturer')
          this.closeModal()
        } else {

        }
      }).catch((e) => {
        this.$bvToast.toast(e.data.message, {
          title: 'Sửa giảng viên',
          variant: 'danger',
          solid: true
        });
      }).finally(()=>{
        this.loading = false
      });
    }
  }
}
</script>

<template>
  <b-modal id="modal-detail-teacher" size="xl" title="Chi tiết giảng viên" centered hide-footer @show="setLecturerData()" >
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent>
        <div class="row">
          <div class="col-4 mb-5">
            <span>Họ và tên <span style="color: red">*</span></span>
            <ValidationProvider name="Họ và tên" :rules="{required:true}" v-slot="{ errors,classes }"
                                vid="Họ và tên">
              <el-input
                  class="mt-2"
                  placeholder="Nhập họ và tên"
                  v-model="query.name"
                  clearable
                  :disabled="!isEdit"
              >
              </el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Số điện thoại <span style="color: red">*</span></span>
            <ValidationProvider name="Số điện thoại" :rules="{required:true, regex: /^(\+84|0)(3|5|7|8|9)\d{8}$/}" v-slot="{ errors,classes }"
                                vid="Số điện thoại">
              <el-input
                  class="mt-2"
                  placeholder="Nhập số điện thoại"
                  v-model="query.phone"
                  clearable
                  :disabled="!isEdit"
              >
              </el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Email <span style="color: red">*</span></span>
            <ValidationProvider name="Email" :rules="{required:true,email:true}" v-slot="{ errors,classes }"
                                vid="Email">
              <el-input
                  class="mt-2"
                  placeholder="Nhập email"
                  v-model="query.email"
                  clearable
                  :disabled="!isEdit"
              >
              </el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Ngày sinh <span style="color: red">*</span></span>
            <ValidationProvider name="Ngày sinh" :rules="{required:true}" v-slot="{ errors,classes }"
                                vid="Ngày sinh">
              <el-date-picker
                  v-model="query.dateOfBirth"
                  type="date"
                  placeholder="Chọn ngày sinh" class="w-100 mt-2"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                  :disabled="!isEdit"
              >
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Giới tính <span style="color: red">*</span></span>
            <ValidationProvider name="Giới tính" :rules="{required:true}" v-slot="{ errors,classes }"
                                vid="Giới tính">
              <el-select v-model="query.gender" placeholder="Chọn giới tính" class="w-100 mt-2" :disabled="!isEdit">
                <el-option
                    v-for="item in ['Nam','Nữ']"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Trung tâm giảng dạy <span style="color: red">*</span></span>
            <ValidationProvider name="Trung tâm giảng dạy" :rules="{required:true}" v-slot="{ errors,classes }"
                                vid="Trung tâm giảng dạy">
              <el-select v-model="query.center" placeholder="Chọn trung tâm giảng dạy" class="w-100 mt-2" filterable :disabled="!isEdit">
                <el-option
                    v-for="item in listCenter"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Chọn nhóm giảng viên</span>
              <el-select v-model="query.groupOfLecturers" placeholder="Chọn nhóm giảng viên" class="w-100 mt-2" filterable :disabled="!isEdit">
                <el-option
                    v-for="item in listTeacherGroup"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                   >
                </el-option>
              </el-select>
          </div>
          <div class="col-4 mb-5">
            <span>Thời gian pass đào tạo <span style="color: red">*</span></span>
            <ValidationProvider name="Thời gian pass đào tạo" :rules="{required:true}" v-slot="{ errors,classes }"
                                vid="Thời gian pass đào tạo">
              <el-date-picker
                  v-model="query.trainingPassTime"
                  type="datetime"
                  placeholder="Chọn thời gian"
                  class="w-100 mt-2"
                  format="HH:mm dd-MM-yyyy"
                  value-format="yyyy-MM-dd HH:mm"
                  :disabled="!isEdit"
              >
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Kích hoạt</span>
            <div class="mt-2">
              <el-switch v-model="query.status" :disabled="!isEdit">
              </el-switch>
            </div>

          </div>

        </div>
        <div class="d-flex justify-content-end" v-if="!isEdit">
          <button type="button" class="btn btn-secondary mr-4" @click="closeModal()">Đóng</button>
          <button type="button" class="btn btn-info" @click="isEdit = true" >Sửa thông tin</button>
        </div>
        <div class="d-flex justify-content-end" v-if="isEdit">
          <button type="button" class="btn btn-secondary mr-4" @click="cancelUpdate()">Hủy</button>
          <button type="button" class="btn btn-info" @click="handleSubmit(updateLecturer)" :disabled="loading"><i class="el-icon-loading" v-if="loading"></i>Cập nhật</button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<style scoped>

</style>