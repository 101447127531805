<script>
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import {CREATE_TEACHER, GET_TEACHER_GROUPS} from "@/core/services/store/teacher/teacher.module";

export default {
  name: "Modal-Add-Teacher",
  data() {
    return {
      query: {
        name: null,
        phone: null,
        email: null,
        dateOfBirth: null,
        gender: null,
        center: null,
        groupOfLecturers: null,
        trainingPassTime: null
      },
      listCenter: [],
      listTeacherGroup: [],
      loading: false
    }
  },
  methods:{
    getCenters(){
      this.$store.dispatch(GET_CENTERS,{limit : 10000, status : 1}).then((res) => {
        this.listCenter = res.data.data;
      });
    },
    getTeacherGroups(){
     this.$store.dispatch(GET_TEACHER_GROUPS).then((data)=>{
       this.listTeacherGroup = data.data
     })
    },
    openModal(){
      this.resetModal()
      this.getCenters()
      this.getTeacherGroups()
    },
    closeModal(){
      this.$bvModal.hide('modal-add-teacher')
    },
    resetModal(){
      this.query = {
        name: null,
        phone: null,
        email: null,
        dateOfBirth: null,
        gender: null,
        center: null,
        groupOfLecturers: null,
        trainingPassTime: null
      }
      this.loading = false;
    },
    createLecturer(){
      this.loading = true;
      const payload = {
        name: this.query.name,
        phone: this.query.phone,
        email: this.query.email,
        status: 1,
        account_type_id: 7,
        is_staff: 2,
        birthday: this.query.dateOfBirth,
        gender: this.query.gender,
        trung_tam_id: this.query.center,
        teacher_group: this.query.groupOfLecturers,
        training_pass_time: this.query.trainingPassTime
      }
      this.$store.dispatch(CREATE_TEACHER, payload ).then((res) => {
        if (!res.error) {
            this.$bvToast.toast(res.message, {
              title: 'Tạo giảng viên',
              variant: 'success',
              solid: true
            });
            this.$emit('reloadListLecturer')
            this.closeModal()
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Tạo giảng viên',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error',
          message: e.data.message
        });
      }).finally(()=>{
        this.loading = false;
      });
    }
  }
}
</script>

<template>
  <b-modal id="modal-add-teacher" size="xl" title="Thêm mới giảng viên" centered hide-footer @show="openModal()">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form id="form" @submit.prevent="handleSubmit(createLecturer)">
        <div class="row">
          <div class="col-4 mb-5">
            <span>Họ và tên <span style="color: red">*</span></span>
            <ValidationProvider name="Họ và tên" rules="required" v-slot="{ errors,classes }"
                                vid="Họ và tên">
            <el-input
                class="mt-2"
                placeholder="Nhập họ và tên"
                v-model="query.name"
                clearable>
            </el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Số điện thoại <span style="color: red">*</span></span>
            <ValidationProvider name="Số điện thoại" :rules="{required:true, regex: /^(\+84|0)(3|5|7|8|9)\d{8}$/}" v-slot="{ errors,classes }"
                                vid="Số điện thoại">
            <el-input
                class="mt-2"
                placeholder="Nhập số điện thoại"
                v-model="query.phone"
                clearable>
            </el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Email <span style="color: red">*</span></span>
            <ValidationProvider name="Email" :rules="{required:true,email:true}" v-slot="{ errors,classes }"
                                vid="Email">
            <el-input
                class="mt-2"
                placeholder="Nhập email"
                v-model="query.email"
                clearable
            >
            </el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Ngày sinh <span style="color: red">*</span></span>
            <ValidationProvider name="Ngày sinh" rules="required" v-slot="{ errors,classes }"
                                vid="Ngày sinh">
            <el-date-picker
                v-model="query.dateOfBirth"
                type="date"
                clearable
                placeholder="Chọn ngày sinh" class="w-100 mt-2"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Giới tính <span style="color: red">*</span></span>
            <ValidationProvider name="Giới tính" rules="required" v-slot="{ errors,classes }"
                                vid="Giới tính">
            <el-select v-model="query.gender" placeholder="Chọn giới tính" class="w-100 mt-2">
              <el-option
                  v-for="item in ['Nam','Nữ']"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Trung tâm giảng dạy <span style="color: red">*</span></span>
            <ValidationProvider name="Trung tâm giảng dạy" rules="required" v-slot="{ errors,classes }"
                                vid="Trung tâm giảng dạy">
            <el-select v-model="query.center" placeholder="Chọn trung tâm giảng dạy" clearable class="w-100 mt-2" filterable>
              <el-option
                  v-for="item in listCenter"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-4 mb-5">
            <span>Chọn nhóm giảng viên</span>
            <el-select v-model="query.groupOfLecturers" clearable placeholder="Chọn nhóm giảng viên" class="w-100 mt-2" filterable>
              <el-option
                  v-for="item in listTeacherGroup"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-4 mb-5">
            <span>Thời gian pass đào tạo <span style="color: red">*</span></span>
            <ValidationProvider name="Thời gian pass đào tạo" rules="required" v-slot="{ errors,classes }"
                                vid="Thời gian pass đào tạo">
            <el-date-picker
                clearable
                v-model="query.trainingPassTime"
                type="datetime"
                placeholder="Chọn thời gian"
                class="w-100 mt-2"
                format="HH:mm dd-MM-yyyy"
                value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>

        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary mr-4" @click="closeModal()">Đóng</button>
          <button type="submit" class="btn btn-info" :disabled="loading"><i class="el-icon-loading" v-if="loading"></i>Thêm mới</button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<style scoped>

</style>