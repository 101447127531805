<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách giảng viên</h3>
        </div>
        <div class="card-title">
          <button class="font-weight-bold font-size-3 btn btn-primary mr-4" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
<!--          <button class="font-weight-bold font-size-3 btn btn-primary mr-4" @click="exportExcelV2">-->
<!--            <i class="la la-file-excel"></i> Xuất excel v2-->
<!--          </button>-->
          <button
              class="font-weight-bold font-size-3  btn btn-success"
              @click="openModalAdd()"
          >Thêm mới
          </button>
          <!--              :to="{ name: 'teacher-create' }"-->

        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="form-group">
            <div class="input-group grid-layout">
              <div class="w-100">
                <p>Giảng viên</p>
                <input type="text" class="form-control" placeholder="Nhập mã, tên, số điện thoại hoặc email "
                       v-on:input="searchTeachers"
                       v-model="query.keyword"
                       aria-describedby="basic-addon2"
                       style="height: 40px">
              </div>
              <div class="w-100">
                <p>Trung tâm</p>
                <el-select filterable class="w-100" placeholder="Chọn trung tâm áp dụng"
                           clearable collapse-tags multiple
                           v-model="query.center_id"
                           @input="searchCenter"
                >
                  <el-option
                      v-for="item in listCenter"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="w-100">
                <p>Nhóm giảng viên</p>
                <el-select v-model="query.groupOfLecturers" placeholder="Chọn nhóm giảng viên" class="w-100 " filterable @change="getQuery()" clearable>
                  <el-option
                      v-for="item in listTeacherGroup"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="w-100">
                <p>Trạng thái</p>
                <el-select filterable class="w-100" placeholder="Trạng thái"
                           clearable
                           v-model="query.status"
                           @input="searchStatus"
                >
                  <el-option
                      v-for="(item, index) in this.status()"
                      :key="index"
                      :label="item"
                      :value="index"
                  >
                    <span style="float: left">{{ item }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="w-100">
                <p>Thời gian pass khóa</p>
                <el-date-picker
                    v-model="query.trainingPassTime"
                    type="daterange"
                    start-placeholder="Ngày bắt đầu"
                    end-placeholder="Ngày kết thúc"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    @change="getQuery()"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center table-bordered">
              <thead>
              <tr style="background-color: #F8F8F8">
                <th scope="col">Mã giảng viên</th>
                <th scope="col">Họ tên giảng viên</th>
                <th scope="col">Số điện thoại</th>
                <th scope="col">Email</th>
                <th scope="col">Trung tâm giảng dạy</th>
                <th scope="col">Nhóm giảng viên</th>
                <th scope="col">Trạng thái</th>
                <th scope="col">Thời gian pass đào tạo</th>
                <th scope="col" class="min-w-100px text-center">Hành động</th>
              </tr>
              </thead>
              <tbody v-if="tableLoading">
              <tr>
                <td colspan="9" style="text-align: center"><i class="el-icon-loading
"></i></td>
              </tr>
              </tbody>
              <tbody v-else-if="teachers.length === 0">
                <tr>
                  <td colspan="9" style="text-align: center">Không có dữ liệu</td>
                </tr>
              </tbody>
              <tbody v-else>
              <tr v-for="(item, index) in teachers" :key="index">
                <td class="">{{parseInt(item.id) + 100000}}</td>
                <td class="">{{item.name}}</td>
                <td class="">{{item.phone}}</td>
                <td class="">{{item.email}}</td>
                <td>{{item?.profile?.center?.name??item?.center_teacher[0].center?.name??''}}</td>
                <td>{{item?.teacher_groups?.length >0 ? item?.teacher_groups[0].name: '' }}</td>
                <td class=""><span class="badge"
                                   v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                                    {{item.status === 1 ? "Hiển thị" : 'Ẩn'}}
                                </span></td>
                <td>
                   {{item?.teacher_infor?.training_pass_time?formatDateTimeVietnam(item?.teacher_infor?.training_pass_time):''}}
                </td>
                <td class="text-center">
<!--                  <router-link :to="{ name: 'teacher-update', params: {id : item.id} }" title="Sửa"-->
<!--                               class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>-->
<!--                  </router-link>-->
<!--                  <router-link :to="{ name: 'teacher-detail', params: {id : item.id} }" title="Xem chi tiết"-->
<!--                               class="btn btn-sm btn-icon mr-2 btn-outline-success"><i class="far fa-eye"></i>-->
<!--                  </router-link>-->
                  <button  title="Xem chi tiết" style="font-size: 5px"
                           class="btn btn-sm btn-icon mr-2 btn-outline-success" @click="openDetailModal(item)"><i class="far fa-eye" style="font-size: 13px"></i>
                  </button>
                  <button
                          :disabled="item?.teacher_groups?.length >0"
                          style="font-size: 5px" :title="item?.teacher_groups?.length >0?'Không thể xóa giảng viên đã có nhóm':'Xóa'" @click="openLecturerDeleteDialog(item)" href="javascript:"
                          class="btn btn-sm btn-icon btn-outline-danger mr-2"><i class="fas fa-trash" style="font-size: 13px"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate d-flex justify-content-between px-10">
        <div></div>
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
        <div style="color: #00aff0">Tổng số bản ghi: {{totalRecord}}</div>
      </div>
    </div>
    <ModalAddTeacher @reloadListLecturer="reloadListLecturer"></ModalAddTeacher>
    <ModalDetailAndEditTeacher
        :lecturer="selectedLecturer"
        @reloadListLecturer="reloadListLecturer"
        :listCenter="listCenter"
        :listTeacherGroup="listTeacherGroup">
    </ModalDetailAndEditTeacher>
    <el-dialog
        title="Xóa giảng viên khỏi nhóm"
        :visible.sync="isLecturerDeleteDialogVisible"
        width="30%"
        top="32vh"
       >
      <p style="color:red;">Dữ liệu sau khi xóa sẽ không thể khôi phục được.</p>
      <p style="color:#000000;">Bạn có chắc chắn muốn xóa giảng viên {{selectedLecturer?.name}}?</p>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-3" style="width: 90px;" @click="isLecturerDeleteDialogVisible = false">Đóng</button>
        <button type="button" class="btn btn-danger" style="width: 90px" @click="deleteLecturer" :disabled="loading">Xóa</button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import {
  DELETE_TEACHER, EXPORT_EXCEL_TEACHER, EXPORT_EXCEL_TEACHER_V2,
  GET_TEACHER_ACCOUNT_TYPES, GET_TEACHER_GROUPS,
  GET_TEACHERS,
} from "../../../core/services/store/teacher/teacher.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {LIST_CENTER} from "@/core/services/store/contract/contract-program.module";
import {GET_CENTERS, GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import {exportExcel} from "../../../core/filters";
import ModalAddTeacher from "@/view/pages/teachers/Modal-Add-Teacher.vue";
import moment from "moment-timezone";
import ModalDetailAndEditTeacher from "@/view/pages/teachers/Modal-Detail-And-Edit-Teacher.vue";

const _ = require('lodash');

export default {
  name: "List-Teacher",
  data() {
    return {
      query : {
        keyword: "",
        page: 1,
        account_position: '',
        is_staff : 2,
        type : 'teacher',
        center_ids : [],
        status: null,
        groupOfLecturers: null,
        trainingPassTime: null
      },
      accountTypes : [],
      cache_account_position : '',
      classSpan : {
        success : 'badge-success',
        danger : 'badge-danger'
      },
      teachers: [],
      listCenter: [],
      is_export_excel: false,
      listTeacherGroup: [],
      selectedLecturer: {},
      isLecturerDeleteDialogVisible: false,
      loading: false,
      tableLoading: false

    };
  },
  components : {
    ModalDetailAndEditTeacher,
    ModalAddTeacher,
    Multiselect,
  },
  computed: {
    ...mapGetters(["getAllTeachers","getTeacherPaginate","getTeacherAccountTypes"]),
    total_pages() {
      return this.getTeacherPaginate?.total_pages || 0;
    },
    totalRecord(){
      return this.getTeacherPaginate?.total || 0;
    }
  },
  beforeMount() {
    this.accountTypes = this.getTeacherAccountTypes;
  },
   async mounted() {
    this.tableLoading = true
    this.getCenter();
    this.getTeacherGroups();
    let account_type = await this.$store.dispatch(GET_TEACHER_ACCOUNT_TYPES);
    this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword.trim() : '';
    this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
    this.query.trainingPassTime = this.$route.query.trainingPassTime
    let account_type_id = this.$route.query.account_position ? this.$route.query.account_position : '';
    this.query.account_position = account_type_id;
     if (this.query.account_position){
       this.cache_account_position = _.find(account_type.data,function (obj){
         return obj.id === parseInt(account_type_id);
       });
     }
     await this.$store.dispatch(GET_TEACHERS, this.query);
     await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý giảng viên"},
      { title: "Danh sách giảng viên" }
    ]);
     this.tableLoading = false
   },
  watch : {
    getTeacherAccountTypes (after, before){
      if (after !== before){
        this.accountTypes = after;
      }
    },
    getAllTeachers(after, before){
      if (after !== before){
        this.teachers = after;
      }
    }
  },
  methods: {
    openLecturerDeleteDialog(lecturer) {
      this.selectedLecturer = lecturer;
      this.isLecturerDeleteDialogVisible = true;
    },
    deleteLecturer(){
      this.loading = true;
      this.$store.dispatch(DELETE_TEACHER, this.selectedLecturer.id).then(() => {
        this.$notify({
          title: 'Success',
          message: 'Xóa giảng viên thành công',
          type: 'success'
        });
      }).catch(()=>{
        this.$notify.error({
          title: 'Error',
          message: 'This is an error message'
        });
      }).finally(()=>{
        this.loading = false;
        this.isLecturerDeleteDialogVisible = false;
      });
    },
    searchTeachers : _.debounce(function() {
      this.getQuery();
    }, 300),
    selectAccountType : function (event){
      this.query.account_position = event != null ? event.id : '';
      this.getQuery();
    },
    clickCallback(pageNum) {
      this.tableLoading = true
      this.$router.push({ path : '' , query : { page : pageNum, keyword : this.query.keyword.trim(), account_position : this.query.account_position}});
      this.$store.dispatch(GET_TEACHERS,{
        page : pageNum,
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher'
      }).finally(()=>{
        this.tableLoading = false
      });
    },
    getQuery : function (){
      this.tableLoading = true
      this.$router.push({ path : '' , query : { keyword : this.query.keyword.trim(), account_position : this.query.account_position , center_ids : this.query.center_ids , status_user : this.query.status,trainingPassTime:this.query.trainingPassTime,groupOfLecturers: this.query.groupOfLecturers}});
      this.$store.dispatch(GET_TEACHERS,{
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher',
        center_ids : this.query.center_ids,
        status_user : this.query.status,
        training_pass_time: this.query.trainingPassTime,
        id_teacher_group: this.query.groupOfLecturers
      }).then(() => {
        this.query.page = 1;
      }).finally(()=>{
        this.tableLoading = false
      });
    },
    getCenter() {

      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1
      }).then((res) => {
        this.listCenter = res?.data?.data;
      });
    },
    status() {
      return ['Ẩn', 'Hiển thị'];
    },
    exportExcelV2() {
      let params = {
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher',
        center_ids : this.query.center_ids,
        status_user : this.query.status
      }
      const HEADER_ROW_EXPORT_TEACHER_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ tên GV',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'account_type',
          value: 'Vị trí',
        },
        {
          key: 'teacher_center_name',
          value: 'Trung tâm',
        },
        {
          key: 'teacher_level',
          value: 'Trình độ',
        },
        {
          key: 'address',
          value: 'Địa chỉ',
        },
        {
          key: 'owner_name',
          value: 'Chủ tài khoản',
        },
        {
          key: 'bank_name',
          value: 'Ngân hàng',
        },
        {
          key: 'province',
          value: 'Tỉnh thành ngân hàng',
        },
        {
          key: 'chi_nhanh_ngan_hang',
          value: 'Chi nhánh ngân hàng',
        },
        {
          key: 'stk',
          value: 'Số tài khoản',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TEACHER_V2, params).then((res) => {
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        exportExcel(HEADER_ROW_EXPORT_TEACHER_LIST, res.data, "Danh sách giảng viên.xlsx", true)
      });
    },
    exportExcel() {
      let params = {
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher',
        center_ids : this.query.center_ids,
        status_user : this.query.status,
        training_pass_time: this.query.trainingPassTime
      }
      const HEADER_ROW_EXPORT_TEACHER_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ tên GV',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'account_type',
          value: 'Vị trí',
        },
        {
          key: 'center_name',
          value: 'Trung tâm',
        },
        {
          key: 'level',
          value: 'Trình độ',
        },
        {
          key: 'address',
          value: 'Địa chỉ',
        },
        {
          key: 'owner_name',
          value: 'Chủ tài khoản',
        },
        {
          key: 'bank_name',
          value: 'Ngân hàng',
        },
        {
          key: 'province',
          value: 'Tỉnh thành ngân hàng',
        },
        {
          key: 'chi_nhanh_ngan_hang',
          value: 'Chi nhánh ngân hàng',
        },
        {
          key: 'stk',
          value: 'Số tài khoản',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TEACHER, params).then((res) => {
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        exportExcel(HEADER_ROW_EXPORT_TEACHER_LIST, res.data, "Danh sách giảng viên.xlsx", true)
      });
    },
    searchCenter: function (event){
      this.query.center_ids = event;
      this.getQuery();
    },
    searchStatus: function (event){
      this.query.status = event;
      this.getQuery();
    },
    openModalAdd(){
      this.$bvModal.show('modal-add-teacher')
    },
    async reloadListLecturer(){
      this.tableLoading = true
      let data = {
        keyword: this.query.keyword,
        page: this.query.page,
        account_position: this.query.account_position,
        is_staff: this.query.is_staff,
        type: this.query.type,
        center_ids: this.query.center_ids,
        status_user: this.query.status,
        groupOfLecturers: this.query.groupOfLecturers,
        trainingPassTime: this.query.trainingPassTime,
        center_id: this.query.center_id
      };
      await this.$store.dispatch(GET_TEACHERS, data);
      this.tableLoading = false
    },
    formatDateTimeVietnam(value) {
      if (value) {
        return moment(String(value)).format('HH:mm:ss DD-MM-YYYY')
      }
    },
    getTeacherGroups(){
      this.$store.dispatch(GET_TEACHER_GROUPS).then((data)=>{
        this.listTeacherGroup = data.data
      })
    },
    openDetailModal(lecturer){
      this.selectedLecturer = {...lecturer}
      setTimeout(()=>{
        this.$bvModal.show('modal-detail-teacher')
      })
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.input-group i:after, .input-group i:before {
  line-height: 1.5;
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px
}

.el-select .el-select__tags {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-select .el-tag {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>